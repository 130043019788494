// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q1Y9-vbl{--rabbet-color:#549445;background:none;color:inherit;height:2.29em;text-transform:uppercase;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:2.29em}@media(hover:hover){.Q1Y9-vbl:hover{background:rgba(84,148,69,.1);color:inherit}}@media(hover:none){.Q1Y9-vbl:active{background:rgba(84,148,69,.1);color:inherit}}.Q1Y9-vbl svg{height:1.36em;width:1.36em}.Q1Y9-vbl svg *{fill:currentColor}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Q1Y9-vbl"
};
module.exports = ___CSS_LOADER_EXPORT___;
