// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kWMwod-v{flex-direction:column-reverse;font-size:.75rem;gap:1.33em;max-width:32em;padding:2.67em 2.67em 0 0;pointer-events:none;right:0;top:0;z-index:2}.zZ-KGEfj{background:#92f22b;border-radius:2em 0 2em 2em;color:#000;gap:1.33em;line-height:1.17em;min-width:18em;padding:1.67em;pointer-events:all;transition:transform .2s ease-in-out,opacity .2s ease-in-out}.tX0Z\\+61N{cursor:pointer}@media(hover:hover){.tX0Z\\+61N:hover{background:#99f339}}@media(hover:none){.tX0Z\\+61N:active{background:#99f339}}.tX0Z\\+61N:active{background:#8bf11d}.h0SSP6T3{font-size:1.5em;font-weight:800;gap:1.28em;line-height:1.22}._7f-CaGCM{gap:1.28em}.XSssG\\+4n{height:2.14em;width:2.36em}.XSssG\\+4n img{border-radius:100%;height:100%;-o-object-fit:cover;object-fit:cover;width:100%}.XSssG\\+4n svg{max-height:100%;max-width:100%}.h9slkojl{background:none;color:#000;cursor:pointer;height:.89em;padding:0;width:.89em}@media(hover:hover){.h9slkojl:hover{opacity:.8}}@media(hover:none){.h9slkojl:active{opacity:.8}}.h9slkojl:active{opacity:.7}.h9slkojl svg>*{stroke:currentColor}._09r1AFwb{-webkit-line-clamp:4}.m0gdeDrw{-webkit-line-clamp:2;font-size:1.17em}.m0gdeDrw,._09r1AFwb{-webkit-box-orient:vertical;display:-webkit-box;overflow:hidden;word-break:break-word}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kWMwod-v",
	"toast": "zZ-KGEfj",
	"toastClickable": "tX0Z+61N",
	"toastHead": "h0SSP6T3",
	"toastHeadGroup": "_7f-CaGCM",
	"toastIcon": "XSssG+4n",
	"toastClose": "h9slkojl",
	"toastTitle": "_09r1AFwb",
	"toastBody": "m0gdeDrw"
};
module.exports = ___CSS_LOADER_EXPORT___;
