// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZMbhySL{--color:#000;background:#92f22b;border:1px solid #549445;border-radius:.2em;color:#000;color:var(--color);font-size:max(1rem,min(1.04vw,1.25rem));font-weight:400;line-height:1.4;padding:.63em 1.2em}@media(hover:hover){.RZMbhySL:hover{color:var(--color)}}@media(hover:none){.RZMbhySL:active{color:var(--color)}}.RZMbhySL:active{filter:brightness(80%)}.RZMbhySL:disabled{cursor:not-allowed;filter:grayscale(100%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "RZMbhySL"
};
module.exports = ___CSS_LOADER_EXPORT___;
