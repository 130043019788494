
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import debounce from "lodash/debounce"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import breakpoints from "@/assets/style/resources/breakpoints/module.scss"

export default Vue.extend({
  props: {
    isDark: { type: Boolean, default: false },
    isQuick: { type: Boolean, default: false },
  },

  data: () => ({
    isMenuOpen: false,
  }),

  computed: {
    ...mapGetters("user", ["isPaid", "isApprovableStatus", "isImpersonalized", "isAdmin"]),
    ...mapGetters("chat", ["unreadCount"]),

    nav () {
      const nav = [
        { to: "/", titleToken: "home", exact: true, plain: true },
        { to: "/video", titleToken: "video", plain: true },
        { to: "/program", titleToken: "program" },
        { to: "/speakers", titleToken: "speakers" },
      ]
      if (this.$i18n.locale === "ru") {
        nav.push(
          { to: "/contest", titleToken: "contest" },
        )
      }
      nav.push(
        { to: "/about", titleToken: "about" },
        { to: "/photo", titleToken: "howItWas" },
        // { to: "/price", titleToken: "price" },
        { to: "/participants", titleToken: "participants" },
        { to: "/networking", titleToken: "networking" },
        { to: "/mentor-hours", titleToken: "mentors" },
        { to: "/exhibitors", titleToken: "exhibitors" },
        { to: "/partners", titleToken: "partners" },
        { to: "/pitches", titleToken: "pitches" },
      )
      if (this.$i18n.locale === "ru") {
        nav.push(
          { to: "/hub", titleToken: "hub" },
        )
      }
      nav.push(
        { to: "/highlights", titleToken: "highlights" },
        { to: "/press-center", titleToken: "pressCenter" },
        // { to: "/contacts", titleToken: "contacts" },
        { to: "/how-to-get", titleToken: "howToGet" },
        { to: "/faq", titleToken: "faq" },
      )
      return nav
    },

    isRegistration (): boolean {
      return Boolean(this.$route.name?.includes("registration"))
    },

    isBtnsVisible (): boolean {
      const { name } = this.$route
      if (!name) return false
      return !name.startsWith("contest") && !name.startsWith("exhibitors")
    },
  },

  watch: {
    $route: {
      handler () { this.isMenuOpen = false },
      deep: true,
    },

    isMenuOpen (isMenuOpen) {
      const { menu } = this.$refs
      if (!menu) return
      if (isMenuOpen) disableBodyScroll(menu)
      else enableBodyScroll(menu)
    },
  },

  mounted () {
    window.addEventListener("resize", this.onResize)
  },
  beforeDestroy () {
    enableBodyScroll(this.$refs.menu)
    window.removeEventListener("resize", this.onResize)
  },

  methods: {
    ...mapActions("user", ["exitImpersonalization", "logout"]),

    onResize: debounce(function () {
      if (window.innerWidth >= breakpoints.lg)
        // @ts-ignore
        this.isMenuOpen = false
    }, 200),

    async quickLogout () {
      if (!this.$auth.loggedIn) return
      await this.logout()
      this.$router.replace(this.localePath("/quick/registration"))
    },
  },
})
