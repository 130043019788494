// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8sV7vY2v{--variant:#000;--scale:1;background:#000;background:var(--variant);border-radius:inherit;color:hsla(0,0%,100%,.5);font-family:Sofia_Sans_Extra_Condensed,sans-serif;font-size:3rem;font-size:calc(3rem*var(--scale));font-weight:700;letter-spacing:.02em;line-height:1;-webkit-user-select:none;-moz-user-select:none;user-select:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initials": "_8sV7vY2v"
};
module.exports = ___CSS_LOADER_EXPORT___;
