
import { Swiper } from "vue-awesome-swiper"
import { nanoid } from "nanoid"
import "swiper/css/swiper.min.css"

export default {
  components: { Swiper },

  props: {
    // https://swiperjs.com/swiper-api#parameters
    options: { type: Object, required: true },
    navTop: { type: Number, default: 50 },
    padding: { type: Number, default: 0 },
  },

  data () {
    const { $style } = this
    return {
      nav: [{ class: $style.prevEl }, { class: $style.nextEl }]
        .map(i => ({ ...i, id: nanoid() })),
    }
  },

  computed: {
    swiperOptions () {
      const { nav } = this
      return {
        grabCursor: true,
        freeMode: true,
        navigation: {
          prevEl: `#${nav[0].id}`,
          nextEl: `#${nav[1].id}`,
          disabledClass: this.$style.hidden,
        },
        ...this.options,
      }
    },
  },
}
