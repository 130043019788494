// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z-kdFPT1{list-style-type:none;max-width:var(--max-w);overflow-x:auto;overflow-y:hidden;padding-top:.15em}._47vxskER{border-bottom:1px solid #549445}._47vxskER .-uF29Xi-{border-bottom:0}.-uF29Xi-{border:1px solid #549445;border-radius:0 1.33em 0 0;color:#000;font-size:max(1rem,min(1.04vw,1.25rem));line-height:1.4;padding:.8em 1em .8em .8em}@media(hover:hover){.-uF29Xi-:hover{color:#000}}@media(hover:none){.-uF29Xi-:active{color:#000}}.-uF29Xi-.nuxt-link-active{background:#92f22b}.-uF29Xi-.nuxt-link-exact-active{cursor:default}@media(max-width:991.98px){.-uF29Xi-{font-size:.88rem}}@media(max-width:575.98px){.-uF29Xi-{font-size:.75rem}}.LgZEj6yo{background:#d91413;border-color:transparent;color:#fff}@media(hover:hover){.LgZEj6yo:hover{color:#fff}}@media(hover:none){.LgZEj6yo:active{color:#fff}}.-uF29Xi-[disabled]{cursor:not-allowed;filter:grayscale(100%)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "z-kdFPT1",
	"containerWithBorder": "_47vxskER",
	"tab": "-uF29Xi-",
	"tabRed": "LgZEj6yo"
};
module.exports = ___CSS_LOADER_EXPORT___;
