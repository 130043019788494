import { render, staticRenderFns } from "./AccountRecUsers.vue?vue&type=template&id=0aa3d493&"
import script from "./AccountRecUsers.vue?vue&type=script&lang=js&"
export * from "./AccountRecUsers.vue?vue&type=script&lang=js&"
import style0 from "./AccountRecUsers.vue?vue&type=style&index=0&id=0aa3d493&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtn: require('/app/components/app/AppBtn.vue').default,AppUser: require('/app/components/app/AppUser.vue').default,AppSwiper: require('/app/components/app/AppSwiper.vue').default})
