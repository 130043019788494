
import buttonMixin from "@/mixins/button"

export default {
  mixins: [buttonMixin],

  props: {
    variant: { type: String, default: "secondary" },
  },
}
