// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k4wMdRMH,.Yq\\+\\+C5-6{margin-bottom:2.44rem}.bfO3wWbL,._9X\\+d7Ukq{margin-top:8.38rem}@media(max-width:991.98px){.bfO3wWbL,._9X\\+d7Ukq{margin-top:6.25rem}}@media(max-width:767.98px){.bfO3wWbL,._9X\\+d7Ukq{margin-top:5rem}}.T8YtjlJk .account-heading{align-items:center;display:flex;gap:1rem;justify-content:space-between;margin-bottom:3.13rem}@media(max-width:767.98px){.T8YtjlJk .account-heading{align-items:start;flex-direction:column}}.T8YtjlJk .account-heading h2{font-family:Sofia_Sans_Extra_Condensed,sans-serif;font-size:2rem;font-weight:700;line-height:1.4;margin:0;text-transform:uppercase}@media(max-width:1365.98px){.T8YtjlJk .account-heading h2{font-size:1.5rem}}@media(max-width:991.98px){.T8YtjlJk .account-heading h2{font-size:1.25rem}}@media(max-width:767.98px){.T8YtjlJk .account-heading h2{font-size:1.13rem}}@media(max-width:991.98px){.T8YtjlJk .account-heading a{font-size:.88rem}}@media(max-width:767.98px){.T8YtjlJk .account-heading a{font-size:.75rem}}.TWzXPM\\+P{font-size:1rem;max-width:100%;width:28.13rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "k4wMdRMH",
	"tabs": "Yq++C5-6",
	"recSessions": "bfO3wWbL",
	"recUsers": "_9X+d7Ukq",
	"layout": "T8YtjlJk",
	"passportModal": "TWzXPM+P"
};
module.exports = ___CSS_LOADER_EXPORT___;
