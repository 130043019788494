
export default {
  props: {
    isLight: { type: Boolean, default: true },
    isQuick: { type: Boolean, default: false },
  },

  computed: {
    isRegistration () {
      return this.$route.name?.includes("registration")
    },
  },
}
