// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V-HFoAg6{background:#000}.x-qj\\+km\\+{color:#92f22b}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "V-HFoAg6",
	"spinner": "x-qj+km+"
};
module.exports = ___CSS_LOADER_EXPORT___;
