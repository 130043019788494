
import { getOtherLocale } from "@/utils/i18n"

export default {
  computed: {
    otherLocale () {
      return getOtherLocale(this.$i18n)
    },
  },
}
