// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooKolw6{font-size:max(2.81rem,min(4.17vw,5rem));line-height:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "tooKolw6"
};
module.exports = ___CSS_LOADER_EXPORT___;
