// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1yMHfAGJ{--w:4px;--bw:1px;--rabbet-color:currentColor;border:1px solid;border:1px solid var(--rabbet-color);border-radius:.25rem;border-top-right-radius:.63rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1yMHfAGJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
