export const state = () => ({
  // См. @/plugins/init.client
  historyLen: 0,
})

export const getters = {
  historyLen: state => state.historyLen ?? 0,
}

export const mutations = {
  SET_HISTORY_LEN: (state, historyLen) => { state.historyLen = historyLen },
}
