
import Vue from "vue"
import { scrollToTop } from "@/utils/_"

export default Vue.extend({
  props: {
    isDark: { type: Boolean, default: false },
  },

  data () {
    return {
      email: "startupvillage@sk.ru",
      tel: "+7 (903) 013-79-86",
      year: this.$config.year,
      socials: {
        // facebook: "https://www.facebook.com/StartupVlg",
        vk: "https://vk.com/startupvlg",
        // instagram: "https://instagram.com/startupvillage?igshid=1to1k4gy75cji",
        // twitter: "https://twitter.com/StartupVlg",
        telegram: "https://t.me/startupvillage_sk",
      },
    }
  },

  computed: {
    oldYears (): number[] {
      const from = 2017
      return Array.from({ length: this.year - from }, (_, i) => from + i)
    },
  },

  methods: { scrollToTop },
})
