// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-BEHtq5A{background:linear-gradient(180deg,#92f22b 0,rgba(146,242,43,0) 98.97%);height:62.5rem;max-height:100vh;pointer-events:none}.Vo1wGoMN{--header-btn-bg-hover:hsla(0,0%,100%,.1);--header-btn-bg-active:rgba(0,0,0,.1);--btn-primary-text-color:#fff;--btn-primary-bg:#92f22b;--btn-primary-bg-hover:#9ef343;--btn-primary-bg-active:#86f113;--btn-primary-border-color:#fff;--btn-secondary-text-color:#fff;--btn-secondary-bg:#000;--btn-secondary-bg-hover:#0d0d0d;--btn-secondary-bg-active:#000;--btn-secondary-border-color:#fff;color:#fff}.ZgHNfdov{--btn-primary-text-color:#92f22b;--btn-primary-bg:#fff;--btn-primary-bg-hover:#f9f9f9;--btn-primary-bg-active:#f2f2f2;--btn-primary-border-color:#92f22b;--btn-secondary-text-color:#000;--btn-secondary-bg:#fff;--btn-secondary-bg-hover:#f9f9f9;--btn-secondary-bg-active:#f2f2f2;--btn-secondary-border-color:#000;color:#000}.V2I-u8Cp{margin-bottom:10.13rem}@media(max-width:1365.98px){.V2I-u8Cp{margin-bottom:8.75rem}}@media(max-width:767.98px){.V2I-u8Cp{margin-bottom:5rem}}.B\\+dfvtfy{--pt:4.88rem;margin-bottom:max(1rem,min(3.33vw,4rem));padding:4.88rem 0 2.38rem;padding:var(--pt) 0 2.38rem}@media(max-width:991.98px){.B\\+dfvtfy{--pt:3rem}}@media(max-width:767.98px){.B\\+dfvtfy{--pt:2rem}}@media(max-width:575.98px){.B\\+dfvtfy{--pt:1.5rem}}.S-NigYlp{font-size:2.69rem;margin-bottom:.5em;margin-top:2.23em;width:-webkit-max-content;width:-moz-max-content;width:max-content}@media(max-width:1365.98px){.S-NigYlp{font-size:2rem}}@media(max-width:767.98px){.S-NigYlp{font-size:1.38rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradient": "-BEHtq5A",
	"container": "Vo1wGoMN",
	"containerLight": "ZgHNfdov",
	"content": "V2I-u8Cp",
	"header": "B+dfvtfy",
	"btn": "S-NigYlp"
};
module.exports = ___CSS_LOADER_EXPORT___;
