// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xDvZ\\+Xc{--py:4.88rem;padding-bottom:4.88rem;padding-bottom:var(--py);padding-top:4.88rem;padding-top:var(--py)}@media(max-width:1365.98px){._3xDvZ\\+Xc{--py:3rem}}.vfSakZRd{color:#000}@media(max-width:1365.98px){.dVBBxx0M{gap:2rem}.j9cWsXfO,.dVBBxx0M{display:flex;flex-direction:column}.j9cWsXfO{align-items:center;text-align:center}}.cx2Ovj0s{line-height:1.36;margin-bottom:1.79em}@media(max-width:1365.98px){.cx2Ovj0s{margin-bottom:1.3em}}._0ebS0M5k{-moz-column-gap:.95em;column-gap:.95em}.vGum1xLI{color:inherit;cursor:pointer;flex-shrink:0;height:2em;width:2em}@media(hover:hover){.vGum1xLI:hover{opacity:.8}}@media(hover:none){.vGum1xLI:active{opacity:.8}}.vGum1xLI:active{opacity:.7}.vGum1xLI svg{height:auto;width:100%}.vGum1xLI svg path{fill:currentColor}.jZcpxq55{color:inherit}@media(hover:hover){.jZcpxq55:hover{color:inherit}}@media(hover:none){.jZcpxq55:active{color:inherit}}.jZcpxq55>:first-child{height:auto;width:12.5rem}.-uZOde80{-moz-column-gap:1em;column-gap:1em;margin-bottom:1.79em}.dz5FgJJF{color:inherit;font-size:1.13rem}@media(hover:hover){.dz5FgJJF:hover{color:#549445}}@media(hover:none){.dz5FgJJF:active{color:#549445}}._2f-uoP\\+r{line-height:1.14;text-align:right}@media(max-width:1365.98px){._2f-uoP\\+r{text-align:center}}.xaHdU06j{color:#549445}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3xDvZ+Xc",
	"containerDark": "vfSakZRd",
	"row": "dVBBxx0M",
	"contacts": "j9cWsXfO",
	"email": "cx2Ovj0s",
	"socials": "_0ebS0M5k",
	"social": "vGum1xLI",
	"logo": "jZcpxq55",
	"years": "-uZOde80",
	"year": "dz5FgJJF",
	"copy": "_2f-uoP+r",
	"age": "xaHdU06j"
};
module.exports = ___CSS_LOADER_EXPORT___;
